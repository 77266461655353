.dropdown-content {
  .search-container {
    input[type="text"] {
      width: 100%;
    }
  }
}

.campaign-creation-select.custom-select {
  &.width-200 {
    .dropdown-button {
      width: 200px;
      display: block;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
    .dropdown-content {
      width: 200px;
      max-height: 200px;
      overflow: auto;
    }
    ul.select-list {
      li {
        span {
          word-break: normal;
          white-space: normal;
        }
      }
    }
  }
  &.width-250 {
    .dropdown-button {
      width: 200px;
      display: block;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
    .dropdown-content {
      width: 250px;
      max-height: 400px;
      overflow: auto;
    }
    ul.select-list {
      li {
        span {
          word-break: normal;
          white-space: normal;
        }
      }
    }
  }
  &.width-300 {
    .dropdown-button {
      width: 200px;
      display: block;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
    .dropdown-content {
      width: 300px;
      max-height: 400px;
      overflow: auto;
    }
    ul.select-list {
      li {
        span {
          word-break: normal;
          white-space: normal;
        }
      }
    }
  }

  &.campaign-creation-select {
    .dropdown-content {
      max-height: 300px;
      overflow: auto;
    }
    .dropdown-button {
      min-width: 100px;
    }
  }

}
.campaign-creation-container {
  .dropdown-content {
    max-height: 300px;
    overflow: auto;
  }
}

//overrides

.box {
  &.selected-account {
    .custom-select {
      .dropdown-content {
        max-height: 155px;
        .select-list li {
          &:hover {
            background-color: $primaryColor !important;
            color: $white !important;
          }
        }
      }
    
      .dropdown-button {
        min-width: 250px;
      }
    }
    .form-control {
      label {
        font-weight: normal;
      }
    }
  }
}

.custom-select {
  &.view-dropdown {
    .dropdown-content {
      overflow: scroll;
    }
  }
  &.creative-campaign {
    .dropdown-content .select-list {
      .selected-option span {
        word-break: break-all;
      }
    }
  }
}

.custom-select {
  &.ai-ad-preview {
    .select-list {
      li {
        line-height: normal;
        padding: 10px !important;
        .label {
          display: block;
        }
      }
    }
    .dropdown-button {
      // height: 52px;
      padding: 10px;
      &::after {
        top: 20px;
      }
    }
    .platform-icon {
      height: 32px;
      font-weight: 700;
      font-size: 14px;
      padding-left: 40px;
      .caption {
        margin-top: 3px;
        font-weight: 500;
        font-size: 10px;
        color: $aiDropdownLabel;
      }
      &.facebook {
        background: url($assetBaseUrl + 'constants/icon/Logo/Facebook.svg') no-repeat left center;
        background-size: 32px
      }
      &.snapchat {
        background: url($assetBaseUrl + 'constants/icon/Logo/Snapchat.svg') no-repeat left center;
        background-size: 32px
      }
      &.instagram {
        background: url($assetBaseUrl + 'constants/icon/Logo/Instagram.svg') no-repeat left center;
        background-size: 32px
      }
      &.tiktok {
        background: url($assetBaseUrl + 'constants/icon/Logo/tiktok.svg') no-repeat left center;
        background-size: 32px
      }
    }
  }
}
.custom-select {
  &.white {
    .dropdown-button {
      background: $white;
      box-shadow: 0px 1px 5px rgba(0, 0, 0, 0.1);
    }
  }
}

.compound-dropdown {
  display: flex;
  gap: 0;
  & > .btn {
    border-radius: 4px 0 0 4px;
  }
  .dropdown.custom-select {
    .dropdown-button {
      border-radius: 0 4px 4px 0;
      width: 36px;
      padding: 0;
      height: 36px;
      background: $primaryColor;
      border-left: 1px solid $white;
      @include buttonShadow;
      &::after {
        border-top-color: white;
        top: 16px;
        right: 13px;
      }
      &.active {
        border-radius: 0 4px 4px 0;
      }
    }
    .dropdown-content {
      width: 255px;
      right: 0;
      &.bottom {
        top: 35px;
      }
    }
  }

  &.large {
    .btn, .dropdown-button {
      font-weight: 600 !important;
      height: 46px !important;
      line-height: 46px !important;
      padding: 0 40px;
      &.dropdown-button {
        &::after {
          top: 20px !important;
        }
      }
    }
    .dropdown-content {
      width: 291px !important;
      &.top {
        bottom: 50px;
      }
    }
  }
}

.ai-ad-preview {
  .dropdown-content {
    max-height: 275px;
    overflow: auto;
  }
}

.compound-dropdown {
  .dropdown.custom-select.download {
    .dropdown-button {
      background-image: url($assetUrl + 'download.svg');
      width: 100%;
      height: 31px;
      background-size: contain;
      background-color: transparent;
      border-radius: 4px;
      border: 0;
      border-left: 0;
      &::after {
        border: 0;
      }
    }
  }
}
