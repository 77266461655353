.ai-ads-modal, .ai-ads-container {
  // width: 1200px;
  // max-width: 1200px !important;
  // height: 90vh;
  // max-height: 90vh;

  // .modal-content {
  //   padding: 0;
  //   height: calc(90vh - 68px);
  //   max-height: calc(90vh - 68px);
  //   min-height: 400px;
  //   display: flex;
  //   justify-content: flex-start;
  //   overflow-x: hidden !important;
  // }

  .title-banner-grey, .content-container {
    width: 1400px;
    overflow: auto;
  }

  .content-container {
    border-radius: 20px;
    margin-bottom: 20px;
    overflow-x: auto;
    flex: initial !important;
    padding: 0;
  }

  .ai-ads-page-content {
    display: flex;
  }
  .ai-ads-footer {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 15px 20px;
    gap: 10px;
    background: $tableRowColorEven;
    border-radius: 0 0 20px 20px;
  }
  .ai-icons {
    padding-top: 10px;
    width: 87px;
    display: flex;
    flex-direction: column;
    border-right: 3px solid $aiAdColumnBorder;
    
    .step-icon {
      cursor: pointer;
    }
    
    .icon {
      &.template {
        background-image: url($assetUrl + 'icons/template.svg');
        &.active {
          background-image: url($assetUrl + 'icons/template-active.svg');
        }
      }
      &.elements {
        background-image: url($assetUrl + 'icons/elements.svg');
        &.active {
          background-image: url($assetUrl + 'icons/elements-active.svg');
        }
      }
      &.text {
        background-image: url($assetUrl + 'icons/text.svg');
        &.active {
          background-image: url($assetUrl + 'icons/text-active.svg');
        }
      }
      &.export {
        background-image: url($assetUrl + 'icons/export.svg');
        &.active {
          background-image: url($assetUrl + 'icons/export-active.svg');
        }
      }
      width: 87px;
      height: 70px;
      padding: 0;
      background-repeat: no-repeat;
      background-position: center;
      position: relative;
      &.active {
        &::after {
          content: '';
          position: absolute;
          right: 0;
          top: 15px;
          height: 40px;
          width: 6px;
          border-radius: 3px;
          background: $primaryColor;
        }
      }
    }
  }
  .content {
    width: auto;

    h3 {
      margin: 20px;
      font-size: 20px;
      font-weight: 700;
    }

    .templates-panel {
      height: calc(100vh - 101px - 60px - 76px);
      overflow: auto;
    }

    .templates {
      padding: 5px 30px;
      display: flex;
      flex-wrap: wrap;
      gap: 30px;
      // height: calc(100vh - 280px);
      overflow: auto;
      // justify-content: flex-start;
      .template {
        width: 222px;
        height: 222px;
        border-radius: 6px;
        cursor: pointer;
        &.active {
          box-shadow: 0 0 0 5px inset $primaryColor;
        }
      }
    }

    .columns {
      display: flex;
      height: calc(100vh - 101px - 60px - 76px);

      .loader-container {
        width: 258px;
        height: 258px;
        display: flex;
        padding: 10px;
        align-items: center;
        justify-content: center;
        border-radius: 5px;
        border: 1px solid $greyText;
        background: $tableBorderColor;
      }
      .input-column {
        // width: 300px;
        overflow: auto;
        border-right: 2px solid $aiAdColumnBorder;
        // height: calc(90vh - 26px - 40px - 30px - 68px);
        max-height: 695px;
        width: 330px;
        overflow: auto;
        .input-column-pad {
          padding: 10px 20px 20px;
        }
        h4 {
          font-weight: 700;
          font-size: 18px;
          line-height: 26px;
        }
        .selected-image {
          margin-top: 20px;
          display: flex;
          align-items: center;
          justify-content: center;
          img {
            border-radius: 5px;
            // width: 100%;
            max-height: 258px;
            max-width: 258px;
          }
        }
        .text-input {
          margin: 10px;
        }
        textarea {
          background: $iceBlue;
          border: 0;
          border-radius: 5px;
          padding: 12px;
          resize: none;
          color: $black;
          width: 100%;
          font-family: Libre Franklin;
          font-size: 16px;
          height: 100px;
          outline: none;
          margin-bottom: 10px;
        }
        textarea.ai-ad-text-input, .image-generation-text-area {
          height: 140px;
          font-size: 14px;
          line-height: 20px;
        }
        .guide-description {
          font-size: 14px;
          line-height: 20px;
          height: 120px;
          margin-top: 20px;
        }
        .ai-icon {
          width: 30px;
          height: 35px;
          border: 0;
          padding: 0;
          cursor: pointer;
          background: transparent;
          background-size: contain;
        }

        .ai-image-results {
          display: flex;
          margin: 2px auto;
          align-items: center;
          justify-content: space-between;
          .result {
            width: 80px;
            height: 80px;
            margin-bottom: 10px;
            position: relative;
            display: flex;
            align-items: center;
            justify-content: center;
            background: $veryLightGrey;
            border-radius: 6px;
            &.active {
              border: 2px solid $primaryColor;
              // box-shadow: 0 0 0 3px inset $primaryColor;
            }
            img {
              max-width: 76px;
              max-height: 76px;
              border-radius: 4px;
              cursor: pointer;
            }
            .remove {
              display: none !important;
              position: absolute;
              z-index: +1;
              width: 20px;
              height: 20px;
              color: $white;
              background: $lightBlue;
              border-radius: 10px;
              display: flex;
              align-items: center;
              justify-content: center;
              right: -8px;
              top: -8px;
              font-size: 21px;
              font-weight: bold;
              cursor: pointer;
            }
          }
        }
        .upload-elements {
          label {
            margin: 0;
          }
          .upload-container {
            display: flex;
            align-items: center;
            gap: 10px;
            margin-top: 20px;
            cursor: pointer;
            .upload-image {
              background: url($assetUrl + 'icons/upload-image.svg') center no-repeat;
              background-size: contain;
              width: 90px;
              height: 90px;
              display: flex;
              align-items: center;
              justify-content: center;
              img {
                max-width: 90px;
                max-height: 90px;
              }
              &.no-bg {
                background: rgba(0, 0, 0, 0.5);
                // border: 1px solid $veryLightGrey;
                border-radius: 5px;
              }
              &.no-bg-transparent {
                background: transparent;
              }
            }
            .upload-text {
              font-size: 15px;
              font-weight: 300;
              color: $uploadTextColor;
            }
          }
        }
        &.hide-column {
          visibility: hidden;
          position: absolute;
          z-index: -1;
          top: -1000000px;
        }

        .column-separator {
          border-bottom: 2px solid $aiAdColumnBorder;
          padding: 20px 20px;
        }
      }
      .preview-panel {
        background: $tableRowColorOdd;
        padding: 5px 20px;
        // width: calc(1200px - 87px - 300px);
        display: flex;
        gap: 15px;
        // max-height: calc(90vh - 26px - 40px - 30px - 68px);
        overflow: auto;

        .middle-panel {
          width: 650px;
        }
        .right-panel {
          width: 260px;
        }
        .name {
          padding: 0 20px;
          margin-bottom: 10px;
          background: $white;
          border-radius: 4px;
          font-size: 20px;
          font-weight: 700;
          height: 48px;
          // line-height: 48px;
          width: 640px;
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
          display: flex;
          flex-direction: row;
          align-items: center;
          gap: 15px;

          input {
            width: 80%;
            font-weight: 600;
            font-size: 16px;
            border-radius: 4px;
            border: 1px solid #e0e2e7;
            padding: 7px 10px;

            &:focus {
              outline: none;
            }
          }
          .name-holder {
            max-width: 570px;
            overflow: hidden;
            text-overflow: ellipsis;
          }
        }
        .loader-bar {
          height: 100%;
          display: flex;
          justify-content: center;
          align-items: center;
          width: 100px;
        }
        .project-name-edit-btn {
          cursor: pointer;
          width: 15px;
          height: 15px;
          background-size: 15px !important;
          background-repeat: no-repeat;
          background: url('https://media.useblitz.com/assets/images/icons/edit-icon-new.svg');
        }
        .canvas-container {
          display: flex;
          flex-direction: column;
          position: relative;
          margin-bottom: 10px;
          canvas, img {
            // height: 500px;
            width: auto;
            border-radius: 5px;
            max-height: calc(100vh - 101px - 60px - 76px - 68px - 10px);
            max-width: 500px;
          }
          &.preview-enabled {
            canvas, img {
              border-radius: 0;
              height: auto;
              max-height: unset;
              &.FB {
                width: 400px;
              }
              &.IG {
                position: absolute;
                width: 286px;
                margin-left: -143px;
                left: 50%;
                top: 110px;
              }
              &.IS {
                position: absolute;
                width: 284px;
                margin-left: -142px;
                left: 50%;
                top: 35px;
                border-radius: 12px;
              }
              &.TT {
                position: absolute;
                width: 283px;
                margin-left: -141px;
                left: 50%;
                top: 1px;
              }
              &.SC {
                position: absolute;
                width: 284px;
                margin-left: -142px;
                left: 50%;
                top: 80px;
                border-radius: 12px;
              }
            }
          }
          canvas.invisible {
            position: absolute;
            left: -50000px;
            visibility: hidden;
          }

          // facebook preview
          .facebook-preview-top {
            width: 400px;
            background: $white;
            border-radius: 7px 7px 0 0;
            padding: 10px;
            font-family: 'Lucida Grande', Verdana, sans-serif;
            font-size: 11px;
            box-shadow: 0 0 5px rgba(0, 0, 0, 0.15);
            .profile-details {
              display: flex;
              gap: 8px;
              // align-items: flex-start;
              .blitz-icon {
                background: url($assetBaseUrl + 'icon/PlatformPreview/Facebook/blitzCircleIcon.svg') no-repeat;
                background-size: 99%;
                width: 30px;
                height: 30px;
              }
              .details {
                width: 100%;
                .user-name {
                  color: $fbBlue;
                  font-size: 11px;
                  font-family: 'Lucida Grande';
                  line-height: 15px;
                }
                .sponsored {
                  background: url($assetBaseUrl + 'icon/PlatformPreview/Facebook/sponsored.png') no-repeat;
                  background-size: contain;
                  width: 100%;
                  height: 18px;
                }
              }
            }
            .preview-text {
              line-height: 16px;
              padding-top: 10px;
            }
          }
          .facebook-preview-bottom {
            width: 400px;
            background: $white;
            padding-bottom: 10px;
            border-radius: 0 0 7px 7px;
            box-shadow: 0 0 5px rgba(0, 0, 0, 0.15);
            .fb-ad {
              background: #eef0f3;
              padding: 10px;
              display: flex;
              align-items: center;
              justify-content: space-between;
              .ad-details {
                .title {
                  font-size: 15px;
                  font-weight: bold;
                  color: $black;
                }
                .description {
                  color: $greyText;
                  font-size: 11px;
                  line-height: 13px;
                }
              }
              .cta {
                background: #e0e2e7;
                padding: 0px 10px;
                line-height: 30px;
                font-size: 11px;
                font-weight: bold;
                color: $black;
                border-radius: 4px;
              }
            }
            .fb-footer {
              background: url($assetBaseUrl + 'icon/PlatformPreview/Facebook/Botton_View.png') no-repeat center;
              width: 100%;
              height: 58px;
              background-size: contain;
            }
          }
          .instagram-feed {
            background: url($assetBaseUrl + 'icon/PlatformPreview/IGFeed/IG_Feed.png') center no-repeat;
            background-size: contain;
            width: 286px;
            height: 620px;
            position: relative;
            .profile-details {
              position: absolute;
              top: 75px;
              left: 15px;
              display: flex;
              gap: 5px;
              align-items: center;
              .blitz-icon {
                background: url($assetBaseUrl + 'icon/PlatformPreview/Facebook/blitzCircleIcon.svg') no-repeat;
                background-size: contain;
                width: 24px;
                height: 24px;
              }
              .user-name {
                font-size: 10px;
                font-weight: bold;
                color: $white;
              }
            }
            .cta {
              position: absolute;
              z-index: +1;
              bottom: 140px;
              left: 0;
              background: rgb(248, 14, 42);
              color: $white;
              text-align: center;
              font-size: 10px;
              font-weight: bold;
              line-height: 26px;
              width: 286px;
            }
            .content {
              position: absolute;
              z-index: +1000;
              bottom: 42px;
              left: 0;
              padding: 12px;
              width: 286px;
              color: $white;
              font-size: 9px;
              line-height: 14px;
              .views {
                font-weight: bold;
              }
            }
          }
          .instagram-story {
            background: url($assetBaseUrl + 'icon/PlatformPreview/IGStory/IGStoryBG.png') no-repeat center;
            background-size: contain;
            width: 286px;
            height: 620px;
            position: relative;
            .top-details {
              position: absolute;
              top: 25px;
              z-index: +1;
              width: 286px;
              left: 0;
              padding: 10px;
              .progress-bar {
                width: 100%;
                height: 3px;
                background: rgba(255, 255, 255, 0.5);
                border-radius: 2px;
                .fill {
                  width: 30%;
                  height: 3px;
                  border-radius: 2px;
                  background: $white;
                }
              }
              .profile-details {
                margin-top: 5px;
                display: flex;
                gap: 5px;
                width: 284px;
                // align-items: flex-start;
                .blitz-icon {
                  background: url($assetBaseUrl + 'icon/PlatformPreview/Facebook/blitzCircleIcon.svg') no-repeat;
                  background-size: 99%;
                  width: 30px;
                  height: 30px;
                }
                .details {
                  width: 100%;
                  color: $white;
                  .user-name {
                    font-size: 10px;
                    line-height: 14px;
                  }
                  .caption {
                    font-size: 9px;
                    line-height: 14px;
                  }
                }
              }
            }
            .options {
              position: absolute;
              z-index: +1;
              right: 10px;
              top: 45px;
              display: flex;
              align-items: center;
              gap: 15px;
              .more {
                background: url($assetBaseUrl + 'icon/PlatformPreview/SnapChat/ThreeDots.png') center no-repeat;
                background-size: contain;
                width: 5px;
                height: 15px;
                transform: rotate(90deg);
              }
              .insta-close {
                background: url($assetBaseUrl + 'icon/PlatformPreview/IGStory/cross.svg') center no-repeat;
                background-size: contain;
                width: 15px;
                height: 15px;
              }
            }
            .cta {
              color: $white;
              font-size: 9px;
              line-height: 30px;
              border-radius: 20px;
              padding: 0 10px;
              text-align: center;
              position: absolute;
              bottom: 35px;
              left: 50%;
              margin-left: -30px;
              background: rgba(255, 255, 255, 0.2);
              font-weight: bold;
            }
          }
          .tiktok {
            background-size: contain;
            width: 283px;
            height: 567px;
            position: relative;
            .top-shadow {
              position: absolute;
              z-index: +1;
              top: 0;
              width: 283px;
              height: 110px;
              background: url($assetBaseUrl + 'icon/PlatformPreview/Tiktok/ShadowTop.png') no-repeat center;
              background-size: contain;
            }
            .top-overlay {
              position: absolute;
              z-index: +2;
              top: 0;
              width: 283px;
              height: 65px;
              background: url($assetBaseUrl + 'icon/PlatformPreview/Tiktok/TopOverlay.png') no-repeat center;
              background-size: contain;
            }
            .bottom-shadow {
              position: absolute;
              z-index: +1;
              bottom: 63px;
              width: 283px;
              height: 110px;
              background: url($assetBaseUrl + 'icon/PlatformPreview/Tiktok/ShadowBottom.png') no-repeat center;
              background-size: contain;
            }
            .bottom-overlay {
              position: absolute;
              z-index: +2;
              bottom: 0;
              width: 283px;
              height: 65px;
              background: url($assetBaseUrl + 'icon/PlatformPreview/Tiktok/BottomOverlay.png') no-repeat center;
              background-size: contain;
            }
            .options-overlay {
              position: absolute;
              z-index: +2;
              bottom: 65px;
              right: 5px;
              width: 60px;
              height: 280px;
              background: url($assetBaseUrl + 'icon/PlatformPreview/Tiktok/OptionsView.png') no-repeat center;
              background-size: contain;
            }
            .blitz-image {
              position: absolute;
              z-index: +2;
              bottom: 180px;
              left: 10px;
              background: url($assetBaseUrl + 'icon/PlatformPreview/Tiktok/UseBlitz.png') no-repeat center;
              background-size: contain;
              width: 70px;
              height: 15px;
            }
            .promoted-music {
              position: absolute;
              z-index: +2;
              bottom: 100px;
              left: 10px;
              background: url($assetBaseUrl + 'icon/PlatformPreview/Tiktok/PromotedMusic.png') no-repeat center;
              background-size: contain;
              width: 100px;
              height: 13px;
            }
            .caption {
              position: absolute;
              bottom: 135px;
              left: 15px;
              z-index: +2;
              font-size: 11px;
              color: $white;
            }
            .cta {
              background: rgb(0, 141, 234);
              position: absolute;
              z-index: +2;
              bottom: 70px;
              left: 15px;
              width: 200px;
              line-height: 25px;
              border-radius: 3px;
              font-size: 12px;
              font-weight: bold;
              color: $white;
              text-align: center;
            }
          }
          .snapchat {
            background: url($assetBaseUrl + 'icon/PlatformPreview/SnapChat/Overlay.png') center no-repeat;
            background-size: contain;
            width: 286px;
            height: 620px;
            position: relative;

            .profile-details {
              position: absolute;
              top: 20px;
              z-index: +1;
              margin-top: 5px;
              display: flex;
              justify-content: space-between;
              width: 286px;
              padding: 10px;
              color: $white;
              .blitz-icon {
                background: url($assetBaseUrl + 'icon/PlatformPreview/Facebook/blitzCircleIcon.svg') no-repeat;
                background-size: 99%;
                width: 30px;
                height: 30px;
              }
              .details {
                color: $white;
                .user-name {
                  font-size: 10px;
                  line-height: 14px;
                }
                .caption {
                  font-size: 8px;
                  margin-top: 5px;
                  color: $greyText;
                  line-height: 14px;
                }
              }
              .options {
                background: url($assetBaseUrl + 'icon/PlatformPreview/SnapChat/ThreeDots.png') center no-repeat;
                background-size: contain;
                width: 15px;
                height: 15px;
              }
            }

            .cta {
              color: $black;
              font-size: 10px;
              line-height: 22px;
              border-radius: 5px;
              padding: 0 10px;
              text-align: center;
              position: absolute;
              z-index: +1;
              bottom: 50px;
              left: 50%;
              margin-left: -35px;
              background: $white;
              font-weight: bold;
            }
          }
        }
      }
    }
  }
  .error.ai-ad {
    background: url($assetUrl + 'icons/warning.svg') 10px 10px no-repeat;
    padding: 10px 10px 10px 45px;
    background-color: $iceBlue;
    border-radius: 5px;
    background-size: 28px;
    color: $uploadTextColor;
    margin-top: 10px;
    font-size: 14px;
    min-height: 50px;
    line-height: 20px;
    display: flex;
    align-items: center;
  }
  .info.ai-ad {
    padding: 10px;
    font-size: .9em;
    border-radius: 5px;
  }
  .modal-foot {
    justify-content: space-between;
    .align-right {
      float: right;
    }
  }

  .or-option {
    display: flex;
    gap: 10px;
    margin: 15px;
    align-items: center;
    justify-content: flex-start;
    line-height: 24px;
  }
  .or-text {
    color: $darkGrey;
    font-weight: 400;
    font-size: 16px;
    line-height: 19px;
  }
  .black-link {
    color: $black;
    background: url($assetUrl + 'icons/album.svg') no-repeat right center;
    background-size: 22px auto;
    padding-right: 30px;
  }
  .loader-text {
    font-size: 15px;
    font-weight: 400;
  }
}
.ai-ads-container {
  margin-top: 10px;
  min-height: calc(100vh - 10px) !important;
}
.ai-ads-content {
  height: calc(100vh - 101px);
}

.z-index-9999 {
  .modal-overlay {
    z-index: 9999;
  }
}

.preview-ad {
  width: 48px;
  height: 48px;
  background: url($assetBaseUrl + 'icon/ToolSvg/PlatformPreviewD.svg') center no-repeat;
  background-size: contain;
  cursor: pointer;
  &.active {
    background: url($assetBaseUrl + 'icon/ToolSvg/PlatformPreviewE.svg') center no-repeat;
    background-size: contain;
  }
}
.ai-ad-tooltip {
  .tooltip-title {
    width: 250px;
    white-space: normal;
    word-wrap: break-word;
    height: auto;
    line-height: 18px;
    font-size: 12px;
    padding-top: 5px;
    padding-bottom: 5px;
    margin-top: 10px;
    bottom: -50px !important;
    &::after {
      top: 8px;
    }
  }
}

.root-class .form-root .form-control {
  &.direction-column {
    margin-bottom: 0 !important;
    .tooltip {
      
    }
  }
}
.download-locked-items {
  position: absolute;
  height: 76px;
  width: 100%;
  button {
    width: 100%;
    height: 38px;
    border: 0;
    background-color: transparent;
    &::before {
      background-position: 14px;
      background-color: transparent;
    }
  }
}

.bottom-align {
  bottom: 55px;
}

.top-align {
  bottom: 4px;
}

.show-image-toggler {
  position: relative;
  height: 36px;
  line-height: 36px;
  cursor: pointer;
  &::after {
    position: absolute;
    right: 20px;
    top: 15px;
    content: '';
    border-top: 7px solid $lightBlue;
    border-bottom: 0px solid transparent;
    border-left: 7px solid transparent;
    border-right: 7px solid transparent;
  }
}
.ai-images-loader {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 20px auto;
  .skeleton {
    border-radius: 6px !important;
    transform: none;
  }
}