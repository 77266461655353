.sticky {
  position: sticky;
  margin-top: 2rem;
  &.bottom {
    bottom: 22px;
  }
  &.right {
    text-align: right;
    margin-right: 6rem;
  }
}
.ellipsis {
  text-overflow: ellipsis;
  white-space: nowrap !important;
  overflow: hidden;
}
.error-text {
  color: red;
  font-size: 14px;
}
.loader {
  text-align: center;
  padding: 50px;
}
.center {
  text-align: center;
}
.left {
  text-align: left;
  justify-content: left !important;
}

.mt-1 {
  margin-top: 1rem !important;
}
.mt-2 {
  margin-top: 2rem !important;
}
.mt-3 {
  margin-top: 3rem !important;
}
.w150 {
  width: 150px !important;
}
.w-36 {
  width: 36px;
}
.w-50 {
  width: 50px;
}
.pad-10 {
  padding: 10px;
}
.vpad-10 {
  padding: 10px 0;
}
.hpad-10 {
  padding: 0 10px;
}

.MuiListItem-root.Mui-selected {
  background: $primaryColor !important;
  color: $white !important;
}

.not-available {
  color: #999999;
  font-size: 14px;
}
.f-14-imp {
  font-size: 14px !important;
}
.f-16 {
  font-size: 16px;
}
.fb {
  font-weight: bold;
}
.pointer {
  cursor: pointer;
}
.not-allowed {
  cursor: not-allowed;
}
.hide {
  display: none;
}
.hide-imp {
  display: none !important;
}
.v-hidden {
  visibility: hidden;
}
.hidden-file-upload {
  visibility: hidden;
  width: 1px;
  height: 1px;
  position: absolute;
  top: 0;
}
.show {
  display: block;
}
.p-40 {
  padding: 40px;
}

.gap-5 {
  gap: 5px;
}

.gap-10 {
  gap: 10px;
}

.gap-15 {
  gap: 15px;
}

.gap-20 {
  gap: 20px;
}

.gap-25 {
  gap: 25px;
}

.flex {
  display: flex;
  .left {
    order: 1;
  }
  .right {
    order: 2;
    margin-left: auto;
  }
  &.direction-column {
    flex-direction: column;
  }
}

.direction-row {
  flex-direction: row;
}

.flex-wrap {
  flex-wrap: wrap;
}

.flex-imp {
  display: flex !important;
}

.flex-1 {
  flex: 1;
}

.flex-2 {
  flex: 2;
}

.flex-3 {
  flex: 3;
}

.ai-center {
  align-items: center;
}

.jc-space-between {
  justify-content: space-between;
}

.jc-center {
  justify-content: center;
}

.o-hidden-imp {
  overflow: hidden !important;
}

.p-38 {
  padding: 38px !important;
}
.f-20 {
  font-size: 20px !important;
}

.fw-400 {
  font-weight: 400;
}
.fw-500 {
  font-weight: 400;
}
.mw-97 {
  max-width: 97% !important;
}

.mt-0 {
  margin-top: 0 !important;
}

.mb-10 {
  margin-bottom: 10px !important;
}

.mb-30 {
  margin-bottom: 30px !important;
}

.p-0 {
  padding: 0 !important;
}

.ta-left {
  text-align: left !important;
}

.ta-center {
  text-align: center;
}

.p-5 {
  padding: 5px !important;
}

.p-10 {
  padding: 10px !important;
}

.p-20 {
  padding: 20px !important;
}

.white-bg {
  background: $white !important;
}

.bg-primary {
  background-color: #ef4834 !important;
}

.ellipsis {
  display: inline-block;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.high-z-index {
  z-index: 100;
}

.toolbar-container {
  margin: 0 15px;
  padding: 15px;
}

.dropdown-content {
  z-index: 100 !important;
}

.no-shadow {
  box-shadow: none !important;
}
.no-bottom-pad {
  padding-bottom: 0 !important;
}

.flex-end {
  display: flex;
  justify-content: flex-end;
}

.pointer {
  cursor: pointer;
}

.m-2 {
  margin: 2rem !important;
}

.mr-1 {
  margin-right: 1rem !important;
}

.ml-1 {
  margin-left: 1rem !important;
}

.mr-4 {
  margin-right: 4rem !important;
}

.full-width {
  width: 100%;
}

.w-p30 {
  width: 30%;
}

.w-p50 {
  width: 50%;
}

.w-p80 {
  width: 80%;
}

.block {
  display: block;
}


.mt-5 {
  margin-top: 5px !important;
}
.mb-5 {
  margin-bottom: 5px !important;
}
.mt-0 {
  margin-top: 0 !important;
}
.mb-0 {
  margin-bottom: 0 !important;
}
.mt-10 {
  margin-top: 10px !important;
}
.mb-10 {
  margin-bottom: 10px !important;
}
.mt-15 {
  margin-top: 15px !important;
}
.mb-15 {
  margin-bottom: 15px !important;
}
.mt-20 {
  margin-top: 20px !important;
}

.mt-24 {
  margin-top: 24px !important;
}
.mb-20 {
  margin-bottom: 20px !important;
}
.mb-24 {
  margin-bottom: 24px !important;
}
.mt-25 {
  margin-top: 25px !important;
}
.mb-25 {
  margin-bottom: 25px !important;
}
.mt-30 {
  margin-top: 30px !important;
}
.mb-30 {
  margin-bottom: 30px !important;
}
.mt-35 {
  margin-top: 35px !important;
}
.mb-35 {
  margin-bottom: 35px !important;
}
.mt-40 {
  margin-top: 40px !important;
}
.mt-50 {
  margin-top: 50px !important;
}
.mb-40 {
  margin-bottom: 40px !important;
}
.mb-50 {
  margin-bottom: 50px !important;
}
.mt-60 {
  margin-top: 60px !important;
}
.mb-60 {
  margin-bottom: 60px !important;
}

.ml-0 {
  margin-left: 0 !important;
}
.mr-0 {
  margin-right: 0 !important;
}
.ml-5 {
  margin-left: 5px !important;
}
.mr-5 {
  margin-right: 5px !important;
}
.ml-10 {
  margin-left: 10px !important;
}
.mr-10 {
  margin-right: 10px !important;
}
.ml-15 {
  margin-left: 15px !important;
}
.mr-15 {
  margin-right: 15px !important;
}
.ml-20 {
  margin-left: 20px !important;
}
.mr-20 {
  margin-right: 20px !important;
}
.ml-25 {
  margin-left: 25px !important;
}
.mr-25 {
  margin-right: 25px !important;
}
.ml-30 {
  margin-left: 30px !important;
}
.mr-30 {
  margin-right: 30px !important;
}
.ml-35 {
  margin-left: 35px !important;
}
.mr-35 {
  margin-right: 35px !important;
}
.ml-40 {
  margin-left: 40px !important;
}
.mr-40 {
  margin-right: 40px !important;
}
.ml-60 {
  margin-left: 60px !important;
}
.mr-60 {
  margin-right: 60px !important;
}
.ml-auto {
  margin-left: auto;
}
.mr-auto {
  margin-right: auto;
}
.rpos {
  position: relative;
}
.justify-right {
  justify-content: right !important;
}
.lh-35 {
  line-height: 35px;
}
.h-p100 {
  height: 100%;
}
.t-opacity {
  transition: opacity 0.3s linear;
}
.layout-container {
  // width: 100%;
  max-width: 1440px;
  margin: auto;
}
.dark-overlay {
  filter: brightness(0.85);
}
.border-none {
  border: none !important;
}
.bottom-toaster {
  position: fixed !important;
  bottom: 10vh;

  .message {
    .close {
      top: 11px !important;
      right: 4px !important;
    }
  }
}
.tlayer {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}
.h-rule {
  border-top: 1px solid $lightGrey;
}