// explicit imports from frontend core
@import '../../node_modules/frontend-core/src/styles/variables';
@import '../../node_modules/frontend-core/src/styles/mixins';

@import 'variables';
@import 'mixins';

:root {
  --color-primary: #ef4834;  /* orangey-red */
  --colors-dark-red: #a73224;
  --colors-dark-grey: #acacac;
  --black: #000000;
  --ice-blue: #e9f0f3;
  --bluish-grey: #87b3c6;
  --white: #ffffff;
}
.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

/* common -------------------- */
* {
  box-sizing: border-box;
}
body {
  font-size: 16px;
}
@media (min-width: 1400px) {
  body {
    font-size: 17px;
  }
}
@media (max-width: 1023) {
  body {
    font-size: 15px;
  }
  .h1 {
    font-size: 1.7rem;
    line-height: 3rem;
  }
}
h1, .h1 {
  font-size: 2.1875rem;
  font-weight: 900;
  line-height: 3.25rem;
  margin: 1rem 0 0.5rem;
  &.nowrap {
    white-space: nowrap;
    @include width968 {
      white-space: normal;
    }
  }
}
h2, .h2 {
  font-size: 1.6rem;
  line-height: 2rem;
  font-weight: bold;
  margin: 1rem 0 0.3rem;
}
h3, .h3 {
  font-size: 1.125rem;
  font-weight: bold;
  margin: 1rem 0 0.5rem;
}
p {
  line-height: 1.625rem;
  margin-bottom: 1rem;
}
ol, ul {
  padding-left: 2rem;
  line-height: 1.7rem;
  margin-bottom: 1rem;
}
ol {
  list-style-type: decimal;
}
ul {
  list-style-type: disc;
}
button.jr-btn {
  background: #222;
  color: #fff;
  font-weight: bold;
  padding: 6px 24px;
}
button.jr-btn:disabled {
  background-color: rgba(0,0,0,0.5);
  color: rgba(255,255,255,0.5);
}
button.jr-btn:hover {
  background: #000;
}
.btn {
  padding: 0 2rem;
  border-radius: 4px;
  background-color: #000000;
  font-weight: bold;
  color: #fff;
  display: inline-block;
  line-height: 3rem;
  border: none;
  cursor: pointer;
  transition: color 0.3s ease;
}

.btn-s {
  padding: 0 1rem;
  line-height: 2rem;
  font-size: 0.9rem;
}
h2 > .number {
  color: #fff;
  border-radius: 4px;
  background-color: #ef4834;
  color: #fff;
  width: 2.2rem;
  line-height: 2.2rem;
  display: inline-block;
  font-size: 1.125rem;
  text-align: center;
  transform: translateY(-8px);
  margin-right: 10px;
}
.app-container {
  position: fixed;
  height: calc(100vh - 82px);
  width: 100%;
  top: 80px;
  left: 0;
  background: #eee;
  border: 0;
}
.footer {
  display: flex;
  justify-content: space-between;
  padding: 3rem 1rem;
  background-color: #ef4834;
  color: #fff;
  flex-direction: column;
  align-items: center;
}
.footer a {
  color: #fff;
}
.text-hero {
  padding: 5rem 0 1rem;
  background-color: #f88240;

  .privacy-cont, .terms-cont {
    padding-left: 30px;
  }
}
.campaign-creation {
  background-color: #e9f0f3;
  position: relative;
  z-index: 0;
}
.campaign-creation::before {
  content: ' ';
  display: block;
  position: absolute;
  top: 61px;
  left: 0;
  width: 100%;
  background: var(--color-primary);
  height: 140px;
  z-index: -1;
}
.campaign-creation::after {
  content: ' ';
  display: block;
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 300px;
  background: url(https://media.useblitz.com/static/blitz/img/dots-tile.svg);
  z-index: -1;
  opacity: 0.28;
}
.inner-page {
  background: #e9f0f3 !important;
}
.inner-page main {
  padding-top: 3.9rem;
  position: relative;
  min-height: 100vh;
}
.main-container {
  display: flex !important;
  flex-direction: column !important;
}
.content-container {
  overflow-x:scroll;
  display: flex;
  flex-direction: column;
  background: #fff;
  width: 97%;
  margin: 0 auto;
  padding: 2px 0;
  border-radius: 20px;
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
  flex: 1 !important;
}
.campaign .content-container {
  position: relative;
  .campaign-alert {
    width: 100%;
    position: absolute;
    top: 185px;
  }
}
.campaign-creation h1 {
  color: #fff;
  font-weight: bold;
  font-size: 1.5rem;
  &.dark {
    color: $black;
  }
  &.right {
    text-align: right;
  }
}
.bl-form label {
  display: block;
  margin-bottom: 1.1875rem;
}
.bl-select {
  max-width: 250px;
}
.fixed-stepper {
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  box-shadow: 0 0 10px rgba(0,0,0,0.2);
  padding-top: 0.2rem;
  background-color: rgba(255, 255, 255, 0.75);
  backdrop-filter: blur(5px);
  z-index: 9;
}
.Mui-error,
.Mui-error input:focus {
  background-color: rgba(255,0,0,0.15);
}
.subscribe-footer {
  position: relative;
  text-align: center;
  padding: 2rem 0;
}
.subscribe-footer::before {
  content: '';
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  background: #979797;
  width: 100%;
  height: 2px;
}
.modal-footer {
  position: relative;
  padding-top: 1.18125rem;
}
.modal-footer-abs {
  position: absolute;
  padding: 10px 0;
  bottom: 0;
  width: 95%;
}
// .modal-footer::before {
//   content: ' ';
//   display: block;
//   width: calc(100% + 96px);
//   background: #f4f4f4;
//   position: absolute;
//   top: 0;
//   left: -48px;
//   height: calc(100% + 36px);
// }
.card-cont {
  margin: 1rem 0;
  border: solid 2px #bababa;
  border-radius: 12px;
}

.card-label-row {
  text-align: start;
  font-size: 0.8em;
  font-weight: 500;
  color: #ACACAC;
  padding-bottom: 10px;
}

.card-row {
  display: flex;
  padding: 25px 20px;
  align-items: center;
  justify-content: space-between;
  height: 34px;
  // border: 1px solid #bababa;
  border-radius: 5px;
  background-color: #E9F0F3;

  .brand-logo-container {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 10px;
  }

  .card-number-element {
    width: 300px;
  }
}

.card-cvv-date-row {
  flex: 1;
  // border: 1px solid #bababa;
  border-radius: 5px;
  width: 100%;
}

.card-row > svg + div {
  width: 100%;
  margin-left: 10px;
}
.card-row > svg {
  width: 20px;
}
.informative-loader {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.bl-form {
  .input-field-group {
    display: flex;
    flex-direction: column;
    gap: 35px;
    margin-bottom: 20px;
    .flex {
      display: flex;
      gap: 15px;
      .btn-line-thick-border {
        height: 35px;
      }
    }
  }
  .input-field {
    margin-top: 0 !important;
    margin-bottom: 0 !important;
  }
}

.card-details-ui {
  .card-cont {
    margin: auto;
    .card-row {
      padding: 18px;
    }
  }
}

@keyframes highlight {
  from { background-color: #b1ffb1; }
  to { background-color: transparent; }
}
@media (max-width: 959px) {
  .footer {
    flex-direction: column;
    align-items: center;
  }
  .footer p {
    margin-bottom: 1rem;
  }
}


.dot-elastic {
  position: relative;
  width: 10px;
  height: 10px;
  border-radius: 5px;
  background-color: #b5cbd7;
  margin: 0 auto;
  color: #b5cbd7;
  animation: dotElastic 1s infinite linear;
}

.dot-elastic::before, .dot-elastic::after {
  content: '';
  display: inline-block;
  position: absolute;
  top: 0;
}

.dot-elastic::before {
  left: -15px;
  width: 10px;
  height: 10px;
  border-radius: 5px;
  background-color: #b5cbd7;
  color: #b5cbd7;
  animation: dotElasticBefore 1s infinite linear;
}

.dot-elastic::after {
  left: 15px;
  width: 10px;
  height: 10px;
  border-radius: 5px;
  background-color: #b5cbd7;
  color: #b5cbd7;
  animation: dotElasticAfter 1s infinite linear;
}

@keyframes dotElasticBefore {
  0% {
    transform: scale(1, 1);
  }
  25% {
    transform: scale(1, 1.5);
  }
  50% {
    transform: scale(1, 0.67);
  }
  75% {
    transform: scale(1, 1);
  }
  100% {
    transform: scale(1, 1);
  }
}

@keyframes dotElastic {
  0% {
    transform: scale(1, 1);
  }
  25% {
    transform: scale(1, 1);
  }
  50% {
    transform: scale(1, 1.5);
  }
  75% {
    transform: scale(1, 1);
  }
  100% {
    transform: scale(1, 1);
  }
}

@keyframes dotElasticAfter {
  0% {
    transform: scale(1, 1);
  }
  25% {
    transform: scale(1, 1);
  }
  50% {
    transform: scale(1, 0.67);
  }
  75% {
    transform: scale(1, 1.5);
  }
  100% {
    transform: scale(1, 1);
  }
}

.my-masonry-grid {
  display: -webkit-box; /* Not needed if autoprefixing */
  display: -ms-flexbox; /* Not needed if autoprefixing */
  display: flex;
  // margin-left: -30px; /* gutter size offset */
  width: auto;
}
.my-masonry-grid_column {
  padding-left: 15px; /* gutter size */
  background-clip: padding-box;
  &:first-child {
    padding-left: 0;
  }
}

.inactive {
  opacity: 0.3;
  pointer-events: none;
}

.centred {
  display: flex;
  align-items: center;
  justify-content: center;
}

.no-data {
  font-size: 20px;
  font-weight: bold;
}

.break-line, .separator-v2 {
  height: 2px;
  margin: 2rem 0;
  position: relative;
  background-color: rgb(232, 239, 243);
}

/* Style your items */
.my-masonry-grid_column > div { /* change div to reference your elements you put in <Masonry> */
  margin-bottom: 15px;
}

@import 'Common';
@import 'Icons';
@import 'Videos';
@import 'Layout';
@import 'Loader';
@import 'Lists';
@import 'Tabs';
@import 'Buttons';
@import 'DataGrid';
@import 'Forms';
@import 'Modal';
@import 'Datepicker';
@import 'Filters';
@import 'Dropdown';
@import 'CustomSelect';
@import 'Campaigns';
@import 'Sortable';
@import 'Cards';
@import 'Accounts';
@import 'Checkbox';
@import 'Projects';
@import 'Project';
@import 'IntroJsOverrides';
@import 'Home';
@import 'Queues';
@import 'Creative';
@import 'Contact';
@import 'Automation';
@import 'Graphs';
@import 'Breadcrum';
@import 'Sheets';
@import 'Scroller';
@import 'Analytics';
@import 'Charts';
@import 'InputWithTags';
@import 'AdCreative';
@import 'Chip';
@import 'Notification';
@import 'Switch';
@import 'Slider';
@import 'Creators';
@import 'Filters';
@import 'AiAds';
@import 'Nav';
@import 'Landing';
@import 'LandingMobile';
@import 'Support';
@import 'LiteCampaigns';
@import 'AdsMockup';
@import 'ChooseCreatives';
@import 'Pexels';
@import 'Blog';
