// tablets
@media only screen and (max-width: 1350px) {
  ul.white-cards {
    display: grid;
    grid-template-columns: auto auto auto;
    .card {
      height: auto;
    }
  }
}
// mobile
@media only screen and (max-width: 900px) {
  .landing {
    .landing-header {
      // position: fixed;
      // width: 100%;
      box-shadow: none;
      ul.header-links {
        position: absolute;
        flex-direction: column;
        width: 100%;
        top: 90px;
        left: 0;
        padding: 0;
        background: rgba(255, 255, 255, 0.9);
        gap: 10px;
        padding-bottom: 10px;
        box-shadow: 0px 5px 5px rgba(0, 0, 0, 0.2);
        visibility: hidden;
        opacity: 0;
        transition: 0.5s all ease-in;
        &.show {
          display: flex;
          opacity: 1;
          visibility: visible;
        }
        li {
          line-height: 40px;
        }
      }
    }

    .bg-content {
      .left {
        order: 1;
      }
      .right {
        order: 2;
        .video-with-bg {
          position: static !important;
          width: auto;
          height: auto;
          left: auto;
        }
      }
    }
    .product-page {
      .video-box.hero-video {
        position: static !important;
        max-width: 100%;
        width: 100%;
        .video-with-bg {
          position: static !important;
          max-width: 100%;
          width: 100%;
          height: auto;
          left: auto;
        }
      }
    }

    .hamburger {
      width: 24px;
      height: 16px;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      .piece {
        width: 24px;
        height: 2px;
        background: $black;
        border-radius: 2px;
        transition: 0.5s all ease-out;
      }
      &.open {
        .top-bun {
          width: 12px;
          transform: translate(2px, 4px) rotate(-135deg);
        }
        .meat {
          transform: rotate(-45deg);
        }
        .bottom-bun {
          width: 12px;
          transform: translate(10px, -2px) rotate(-135deg);
          // transform: translateY(12px);
        }
      }
    }
    ul.white-cards {
      display: grid;
      grid-template-columns: none;
      flex-direction: column;
      padding: 10px;
      box-sizing: border-box;
    }

    .brands {
      ul.brands-list {
        grid-template-columns: auto auto;
        padding: 0;
      }
    }

    .flex {
      flex-direction: column;
      .left, .right {
        width: 100%;
      }
    }

    .case-study-container {
      padding: 60px 0 50px 0;
      .case-study  {
        flex-direction: column;
        .video-pane {
          display: flex;
          justify-content: center;
        }
        .content-pane {
          margin-top: 80px;
          .mt-60 {
            margin-top: 30px !important;
          }
        }
      }
    }


    .h1 {
      font-size: 40px;
      line-height: 53px;
      text-align: left;
    }
    .h2 {
      font-size: 30px;
      line-height: 43px;
      text-align: left;
    }
    .h3 {

    }
  }

  .btn {
    &.v2 {
      &.large {
        height: 60px;
        padding: 0 35px;
        line-height: 60px;
      }
    }
  }

  .orange-link {
    &.medium {
      font-weight: 500;
      font-size: 15px !important;
      line-height: 20px;
      letter-spacing: -0.2px;
    }
  }


  .landing-footer {
    .top {
      .layout-container {
        height: auto;
        align-items: center;
        justify-content: center;
        padding: 30px 0;
      }
      .title {
        max-width: 730px;
        font-size: 35px;
        font-weight: 700;
        line-height: 52px;
        letter-spacing: 0.02em;
        text-align: center;
      }
      button {
        margin: 20px auto 0 auto;
        z-index: +1;
      }
    }
    .bottom {
      height: auto;
      .left {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
      }
      .footer-separator {
        padding: 30px 0 15px 0;
      }

    }

    .input-cta {
      width: 100%;
      input[type="text"] {
        width: auto;
        max-width: calc(100% - 60px);
      }
    }
  }
  .ornament-holder {
    .ornament {
      &.top-left {
        left: 40px !important;
      }
      &.top-right {
        right: 40px !important;
      }
      &.bottom-left {
        left: 40px !important;
      }
      &.bottom-right {
        right: 40px !important;
      }
    }
  }

  .after-header-container {
    .after-header {
      height: auto;
      flex-direction: column;
      align-items: flex-start;
      .head-pane {
        padding-top: 20px;
        .title {
          font-size: 18px;
          line-height: 22px;
        }
        .description {
          font-size: 24px;
          line-height: 28px;
          &.small {
            font-size: 18px;
            line-height: 22px;
          }
        }
      }
      .content-pane {
        width: 100%;
        display: flex;
        padding: 20px;
        justify-content: center;
      }
    }
  }
  .landing {
    .section-container {
      margin: 60px auto;
      .section {
        padding: 30px 50px;
        flex-direction: column;
        gap: 80px;
        .left-pane {
          order: 2;
        }
        .right-pane {
          order: 1;
        }
        &.reverse {
          .left-pane {
            order: 2;
          }
          .right-pane {
            order: 1;
          }
        }
      }
    }

    .alt-section-container {
      margin: 60px auto;
      .alt-section {
        padding: 30px 50px;
        flex-direction: column;
        gap: 120px;
        max-width: 100%;
        .left-pane {
          order: 2;
          img {
            max-width: 100%;
            margin: auto !important;
          }
        }
        .right-pane {
          order: 1;
          img {
            max-width: 100%;
            margin: auto !important;
          }
        }
        &.reverse {
          gap: 60px;
          .left-pane {
            order: 2;
          }
          .right-pane {
            order: 1;
            display: flex;
          }
        }
      }
    }

    .team-section-container {
      margin: 60px auto;
      .team-section {
        flex-direction: column;
        .left-pane {
          flex-direction: column;
          .team-india, .team-us {
            max-width: 100%;
            margin-left: 0;
          }
        }
      }
    }

    .team-members-container {
      .teams-ring {
        display: none;
      }
      .team-members {
        .members {
          display: flex;
          flex-direction: column;
          justify-content: center;
          .member {
            width: 250px;
            height: 250px;
          }
        }
      }
    }

    .carousel-cards {
      padding: 20px 0px;
      display: block;
      white-space: nowrap;
      .card {
        display: inline-block;
        font-size: 14px;
        max-width: 100%;
        width: 100%;
        min-width: unset;
        line-height: 24px;
        padding: 20px;
        margin-right: 10px;
        .quote, .brand {
          width: 100%;
        }
        .content {
          display: flex;
          align-items: center;
          white-space: break-spaces;
        }
        &:last-child {
          margin-right: 0;
        }
      }
      .controls {
        display: block;
        opacity: 0.4;
      }
    }

    .center-video, .right-pane {
      img, .video-controls img {
        max-width: 100% !important;
        width: 100%;
        margin-left: auto !important;
      }
      .video-controls {
        margin-left: auto !important;
      }
    }
  }

  .meta-partners {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
  }

  .subscription-plans {
    .upgrade-modal-body {
      .flex {
        align-items: center;
      }
    }
  }

}

@media only screen and (max-width: 600px) {
  .meta-partners {
    img {
      width: 100%;
    }
  }
}