.dropdown {
  .dropdown-button {
    display: flex;
    align-items: center;
    font-weight: bold;
    border-radius: 4px;
    cursor: pointer;
    padding-right: 30px;
  }
  .dropdown-content {
    label {
      .remove {
        top: 8px;
      }
    }
  }
  &.audience-menu, &.arrow-margin, &.ad-account-select {
    .dropdown-content {
      box-shadow: 0 4px 5px rgb(0 0 0 / 10%);
      border-radius: 0 0 4px 4px;
    }
  }
  &.disabled {
    .dropdown-button {
      color: $greyText;
    }
  }
}
