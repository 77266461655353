.upgrade-btn {
  background-color: #24A346 !important;
  color: white !important;
  font-size: 16px !important;
  padding: 8px 24px !important;
  border-radius: 5px;
}

.upgrade-modal-container {
  .modal-overlay {
    z-index: 9999 !important;
  }
}
.upgrade-modal {
  min-width: 400px;
  max-width: 90vw !important;
  width: fit-content;

  .modal-head {
    font-weight: 700;
    font-size: 24px;
    line-height: 38px;
    color: #282C4B;
    border-bottom: 1px solid #e8edf0;
    padding: 5px;
  }

  .modal-content {
    padding-top: 0px;
  }

  .close {
    height: 23px !important;
  }
}

.upgrade-modal-body {
  display: flex;
  flex-direction: column;
  margin-bottom: 5px;
  justify-content: center;

  .period-selection-area {
    padding: 15px 20px;
    display: flex;
    justify-content: center;
    align-items: center;

    .period-title {
      font-family: 'Libre Franklin';
      font-style: normal;
      font-weight: 400;
      font-size: 12px;
      line-height: 13px;

      text-align: center;
      letter-spacing: 0.02em;

      color: #5D6473;

      .discount-rate {
        color: #EF4834
      }
    }

    .dropdown-button {
      font-size: 14px;
    }

    .period-selection-dd {
      font-size: 12px;

      .option-btn {
        background-color: white;
        width: 100%;
        height: 100%;
        border: none;
        padding: 10px;
        cursor: pointer;
        font-size: 12px;
        font-weight: bold;
      }

      .dropdown-button {
        font-size: 12px;
        padding: 5px 10px;
        padding-right: 30px;

        &::after{
          top: 10px;
        }
      }
    }
  }

  .offer-card {
    flex: 1;
    border: 1px solid #E5E5E5;
    border-radius: 20px; 
    max-width: 300px;
    min-width: 340px;
    display: flex;
    flex-direction: column;
    padding: 7px 15px 7px 15px;

    .slider {
      width: 100%;
      margin-top: 15px;
      accent-color: #EF4834;
      border: none;
      outline: none;
    }

    input[type="range"] {
      /* To hide ordinary range input */
      -webkit-appearance: none;
    
      margin-right: 15px;
      height: 7px;
      background: lightgray;
      border-radius: 5px;
      
      /* Range progress background is set */
      background-image: linear-gradient(#EF4834, #EF4834);
      background-size: 0% 100%;
      background-repeat: no-repeat;
    }
    
    /* Thumb styles */
    input[type="range"]::-webkit-slider-thumb {
    
      /* To hide ordinary thumb */
      -webkit-appearance: none;
      
      height: 15px;
      width: 15px;
      border-radius: 50%;
      
      /* Since range input is created manually, thumb background can be vary in color */
      background: #EF4834; 
      cursor: pointer;
    }

    input[type="range"]::-moz-range-thumb {
      background-color: #EF4834; 
      border-radius: 50%;
      border-color: white;
    }

    input[type="range"]::-moz-range-track {
      background-color: lightgray;
      height: .2em;
    }

    input[type="range"]::-moz-range-progress {
      background-color: #EF4834;
    }
    
    


    .top-section {
      border-bottom: 1px solid #E5E5E5;
      flex: 1;

      .sub-details {
        margin-top: 3px;
        font-style: normal;
        font-weight: 400;
        font-size: 12px;
        line-height: 18px;
        letter-spacing: 0.02em;
        color: #5D6473;
        min-height: 160px;

        .sub-detail-card {
          background-color: #E9F0F3;
          width: 100%;
          display: flex;
          justify-content: center;
          padding: 10px;
          border-radius: 4px;
          margin-top: 10px;
        }

        .top-panel-details {
          min-height: 75px;
          display: flex;
          flex-direction: column;

          div {
            display: flex;
            flex-direction: row;
            gap: 5px;
          }
        }
      }

      .chip-bg {
        background-color: #E9F0F3;
        padding: 2px 10px;
        border-radius: 14px;
        font-weight: bold;
      }

      .title {
        font-size: 10px;
        font-weight: 500;
        line-height: 17px; 
      }

      .basic {
        color: #5D6473
      }

      .pro {
        color: #EF4834
      }

      .price-container {
        margin-top: 4px;
        display: flex;
        align-items: center;
        
        .price {
          font-style: normal;
          font-weight: 700;
          font-size: 24px;
          line-height: 30px;
          letter-spacing: 0.02em;
    
          color: #333333;
        }

        .month {
          margin-left: 5px;
          font-style: normal;
          font-weight: 400;
          font-size: 12px;
          line-height: 15px;

          text-align: center;
          letter-spacing: 0.02em;

          color: #5D6473;
        }

        .muted-month {
          margin-left: 10px;
          color: #5D6473;
          font-size: 14px;
        }

        .muted-annual {
          margin-left: 5px;
          font-size: 12px;
          color: #5D6473;
        }
      }

      .annual-offer {
        // min-height: 35px;
        margin-top: 15px;
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        line-height: 17px;
        
        letter-spacing: 0.02em;
        
        color: #5D6473; 

        .custom-checkbox-container {
          .label {
            margin-left: 5px;
          }
        }
      }
    }

    .bottom-section {
      flex: 3;
      padding: 5px 0 10px 0;

      .offer-option {
        display: flex;
        flex-direction: row;
        margin-top: 10px;
        .green-circle-checkmark {
          position: relative;
          width: 15px;
          min-width: 15px;
          height: 15px;
          border-radius: 50%;
          background-color: #28CC91;

          &::before {
            content: "\2713";
            position: absolute;
            top: 50%;
            left: 53%;
            transform: translate(-50%, -50%);
            color: white;
            font-size: 7px;
            font-weight: bold;
          }
        }
      
        .offer-text {
          margin-left: 10px;
          font-style: normal;
          font-weight: 400;
          font-size: 12px;
          line-height: 16px;

          letter-spacing: 0.02em;

          color: #5D6473;
        }
      }
    }

    .footer-section {
      display: flex;
      justify-content: center;
      align-items: flex-end;
      flex: 1;
      padding: 8px 0;

      button {
        cursor: pointer;
        border: none;
        background-color: #EF4834;
        color: white;
        font-size: 14px;
        font-weight: 500;
        width: 95%;
        padding: 8px 0;
        border-radius: 4px;
      }

      .warning-text {
        font-weight: 600;
        font-size: 14px;
        color: #EF4834;
      }
    }

    .cc-info-msg {
      text-align: center;
      height: 20px;
      font-weight: 400;
      font-size: 12px;
      color: #5D6473;
    }
    
  }

  .active-plan {
    border: 1px solid #EF4834 !important;
    box-shadow: 0px 0px 0px 4px rgba(239, 72, 52, 0.14) !important;
  }

  .payment-container {
    min-width: 400px;
    max-width: 500px;
    width: 100%;
    
    .back-container {

      button {
        background: none;
        border: none;
        color: #5D6473;
        font-size: 12px;
        cursor: pointer;
      }
    }

    .card-cont {
      border: none;
    }

    .pay-btn {
      cursor: pointer;
      margin-top: 10px;
      padding: 13px;
      width: 100%;
      background: #EF4834;
      border: 2px solid #FFFFFF;
      box-shadow: 0px 30px 50px rgba(168, 22, 75, 0.15);
      border-radius: 5px;
      color: white;
      font-weight: 700;
      font-size: 18px;
      line-height: 21px;
      text-align: center;
      letter-spacing: 0.02em;
    }

    .summary-text {
      margin-top: 20px;
      text-align: left;
      font-weight: 400;
      font-size: 14px;
      line-height: 19px;
      color: #5D6473;
    }

    .payment-loading-container {
      .loader-container {
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 20px;

        .circle {
          margin: 20px 0;
          width: 100px;
          height: 100px;
          border-radius: 50%;
          background-color: #FFF7E8;
          display: flex;
          justify-content: center;
          align-items: center;

          .sk-fading-circle {
            width: 40px;
            height: 40px;
            position: relative;
          }

          .sk-fading-circle .sk-circle {
            width: 100%;
            height: 100%;
            position: absolute;
            left: 0;
            top: 0;
          }

          .sk-fading-circle .sk-circle:before {
            content: '';
            display: block;
            margin: 0 auto;
            width: 15%;
            height: 15%;
            background-color: #FFB118;
            border-radius: 100%;
            -webkit-animation: sk-circleFadeDelay 1.2s infinite ease-in-out both;
                    animation: sk-circleFadeDelay 1.2s infinite ease-in-out both;
          }
          .sk-fading-circle .sk-circle2 {
            -webkit-transform: rotate(30deg);
                -ms-transform: rotate(30deg);
                    transform: rotate(30deg);
          }
          .sk-fading-circle .sk-circle3 {
            -webkit-transform: rotate(60deg);
                -ms-transform: rotate(60deg);
                    transform: rotate(60deg);
          }
          .sk-fading-circle .sk-circle4 {
            -webkit-transform: rotate(90deg);
                -ms-transform: rotate(90deg);
                    transform: rotate(90deg);
          }
          .sk-fading-circle .sk-circle5 {
            -webkit-transform: rotate(120deg);
                -ms-transform: rotate(120deg);
                    transform: rotate(120deg);
          }
          .sk-fading-circle .sk-circle6 {
            -webkit-transform: rotate(150deg);
                -ms-transform: rotate(150deg);
                    transform: rotate(150deg);
          }
          .sk-fading-circle .sk-circle7 {
            -webkit-transform: rotate(180deg);
                -ms-transform: rotate(180deg);
                    transform: rotate(180deg);
          }
          .sk-fading-circle .sk-circle8 {
            -webkit-transform: rotate(210deg);
                -ms-transform: rotate(210deg);
                    transform: rotate(210deg);
          }
          .sk-fading-circle .sk-circle9 {
            -webkit-transform: rotate(240deg);
                -ms-transform: rotate(240deg);
                    transform: rotate(240deg);
          }
          .sk-fading-circle .sk-circle10 {
            -webkit-transform: rotate(270deg);
                -ms-transform: rotate(270deg);
                    transform: rotate(270deg);
          }
          .sk-fading-circle .sk-circle11 {
            -webkit-transform: rotate(300deg);
                -ms-transform: rotate(300deg);
                    transform: rotate(300deg); 
          }
          .sk-fading-circle .sk-circle12 {
            -webkit-transform: rotate(330deg);
                -ms-transform: rotate(330deg);
                    transform: rotate(330deg); 
          }
          .sk-fading-circle .sk-circle2:before {
            -webkit-animation-delay: -1.1s;
                    animation-delay: -1.1s; 
          }
          .sk-fading-circle .sk-circle3:before {
            -webkit-animation-delay: -1s;
                    animation-delay: -1s; 
          }
          .sk-fading-circle .sk-circle4:before {
            -webkit-animation-delay: -0.9s;
                    animation-delay: -0.9s; 
          }
          .sk-fading-circle .sk-circle5:before {
            -webkit-animation-delay: -0.8s;
                    animation-delay: -0.8s; 
          }
          .sk-fading-circle .sk-circle6:before {
            -webkit-animation-delay: -0.7s;
                    animation-delay: -0.7s; 
          }
          .sk-fading-circle .sk-circle7:before {
            -webkit-animation-delay: -0.6s;
                    animation-delay: -0.6s; 
          }
          .sk-fading-circle .sk-circle8:before {
            -webkit-animation-delay: -0.5s;
                    animation-delay: -0.5s; 
          }
          .sk-fading-circle .sk-circle9:before {
            -webkit-animation-delay: -0.4s;
                    animation-delay: -0.4s;
          }
          .sk-fading-circle .sk-circle10:before {
            -webkit-animation-delay: -0.3s;
                    animation-delay: -0.3s;
          }
          .sk-fading-circle .sk-circle11:before {
            -webkit-animation-delay: -0.2s;
                    animation-delay: -0.2s;
          }
          .sk-fading-circle .sk-circle12:before {
            -webkit-animation-delay: -0.1s;
                    animation-delay: -0.1s;
          }

          @-webkit-keyframes sk-circleFadeDelay {
            0%, 39%, 100% { opacity: 0; }
            40% { opacity: 1; }
          }

          @keyframes sk-circleFadeDelay {
            0%, 39%, 100% { opacity: 0; }
            40% { opacity: 1; } 
          }
        }
      }
    }

    .payment-failed-container {
      .failed-img-container {
        display: flex;
        justify-content: center;
        align-items: center;
        padding-top: 10px;

        .failed-img {
          margin: 20px 0;
          width: 120px;
          height: 120px;
          background: url(https://media.useblitz.com/assets/images/credit-card-failed.svg);
          background-size: contain;
          background-repeat: no-repeat;
        }

        .success-img {
          margin: 20px 0;
          width: 120px;
          height: 120px;
          background: url(https://media.useblitz.com/assets/images/credit-card-success.svg);
          background-size: contain;
          background-repeat: no-repeat;
        }
      }

      .footer-btns {
        padding-top: 10px;
        gap: 15px;
        display: flex;
        flex-direction: row;

        .retry-btn {
          cursor: pointer;
          flex: 1;
          font-size: 16px;
          font-weight: bold;
          background-color: white;
          height: 36px;
          line-height: 36px;
          border-radius: 4px;
          padding: 0 1rem;
          // box-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.2), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12);
          color: #7F7F7F;
          border: 1px solid #7f7f7f !important;
        }

        .enter-btn {
          flex: 2;
        }
      }
    }
  }

  .success-body {
    margin-top: 10px;

    .footer-area {
      display: flex;
      justify-content: flex-end;
      padding-top: 10px;
    }
  }
}