.blog {
  margin: 30px auto;
  width: 1020px;
  .blog-hero-image {
    width: 1020px;
    margin: auto;
    img {
      max-width: 100%;
      border-radius: 10px;
    }
  }
  h2 {
    font-size: 46px;
    line-height: 1.1em;
    font-weight: 600;
    margin-bottom: 2rem;
  }

  h3 {
    font-size: 30px;
    line-height: 1.1em;
    font-weight: 600;
    margin: 30px auto;
  }

  h4 {
    font-size: 25px;
    line-height: 1.1em;
    font-weight: 600;
    margin: 10px auto;
  }

  h5 {
    font-size: 20px;
    font-weight: 600;
    margin: 10px auto;
  }

  p {
    font-size: 15px;
    margin-bottom: 30px;
    text-align: justify;
  }

  i {
    font-style: italic;
  }

  ul {
    li {
      margin-top: 10px;
    }
  }
  .sub-head {
    font-size: 20px;
    line-height: 1.2em;
    font-weight: 300;
    color: $primaryColor;
    margin-bottom: 0.5rem;
  }
  a {
    color: $primaryColor !important;
  }
  .back-link {
    display: block;
    margin-bottom: 15px;
    color: $primaryColor; 
    &:visited {
      color: $primaryColor;
    }
  }
  .blog-details {
    margin: 40px auto;
    display: flex;
    align-items: center;
    justify-content: space-between;
    .author, .date {
      color: $blogGrey;
      font-size: 14px;
    }
    .author {
      display: flex;
      align-items: center;
      gap: 10px;
      .author-image {
        width: 40px;
        height: 40px;
        min-height: 40px;
        min-width: 40px;
        border-radius: 20px;
        background-size: cover;
      }
    }
  }
  .blog-content {
    width: 840px;
    margin: auto;
    font-family: Poppins;
    color: $graphite;
    * {
      font-family: Poppins;
      color: $graphite;
    }
  }

  .posts {
    display: flex;
    width: 100%;
    gap: 30px;
    flex-wrap: wrap;
    .post {
      cursor: pointer;
      width: 300px;
      img {
        width: 100%;
        border-radius: 10px;
      }
      .sub-head {
        margin: 12px auto;
      }
      .title {
        margin-top: 10px;
        margin-bottom: 10px;
        font-size: 24px;
        line-height: 1.6rem;
        font-weight: 600;
      }
      .blog-details {
        margin: 10px auto;
      }
    }
  }
}

@media only screen and (max-width: 900px) {
  .blog {
    width: auto;
    .blog-hero-image {
      text-align: center;
      width: calc(100% - 20px);
      img {
        width: calc(100% - 20px);
      }
    }
    .blog-content {
      width: calc(100% - 20px);
    }
    .posts {
      flex-direction: column;
      align-items: center;
    }

    iframe {
      width: calc(100% - 20px);
    }
  }
}
